
import { Component, Prop, Vue } from "vue-property-decorator";

// import API
import { negativeActionFiltered, negativeActionHandle, negativeActionReadAll, negativeActionRemove, negativeActionVerify } from "@/apiClient/negativeActionEndpoints"
import { CONSTANTS } from "@/apiClient/constants";
import { adGroupRead } from "@/apiClient/adGroupEndpoints";
import { adGroupTypeReadAll } from "@/apiClient/adGroupTypeEndpoints";

@Component
export default class NegativeActionList extends Vue {

    // LOADING
    private isLoading: boolean = false;
    private hasLoaded: boolean = false;

    private isValidating: boolean = false;
    private hasValidated: boolean = false;

    private isHandling: boolean = false;
    private hasHandled: boolean = false;

    private CONSTANTS = CONSTANTS;

    // BUTTON  
    private btnLoad?: HTMLButtonElement;

    // SORT
    private columnTextOrder: number = 1;

    // MOUNTED
    public mounted() {
        console.log("mounted()");

        this.hasLoaded = false;
        this.btnLoad = <HTMLButtonElement> document.getElementById("btnLoad");

        this.readALL();
    }
    
    // API
    public async readALL() {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            // await apiCall here
            await negativeActionReadAll();
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    }
    public async readList(actionStatus: number) {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            // await apiCall here
            await negativeActionFiltered(actionStatus);
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    }
    public async remove(negativeActionId: number) {
        try {
            // await apiCall here
            await negativeActionRemove(negativeActionId);
            await this.readALL();
        } catch (error) {
        }
    }
    public async validateAll() {
        this.isValidating = true;
        await negativeActionVerify();
        await negativeActionReadAll();
        this.isValidating = false;
    }
    public async handleAll() {
        this.isHandling = true;
        await negativeActionHandle();
        await negativeActionReadAll();
        this.isHandling = false;
    }

    // HELPERS
    public getDestination(destinationCategoryId: number) {
        let found = false;
        for (let category of this.$store.state.categoryCache.categoryList) {
            // console.log(destinationCategoryId, category.id)
            if (category.id === destinationCategoryId) {
                return category.name;
            }
        }
        return false;
    };
    public getCategory(categoryId: string) {
        for (let category of this.$store.state.categoryCache.categoryList) {
            // console.log(category)
            if (category.id === parseInt(categoryId)) return category.name;
        }
        return "NO CATEGORY";
    };
    public getCampaignType(campaignTypeId: string) {
        for (let campaignType of this.$store.state.campaignTypeCache.campaignTypeList) {
            if (campaignType.id === parseInt(campaignTypeId)) return campaignType.name;
        }
        return "NO CAMPAIGN TYPE";
    };
    public getAdGroupType(adGroupTypeId: string) {
        for (let adGroupType of this.$store.state.adGroupTypeCache.adGroupTypeList) {
            if (adGroupType.id === parseInt(adGroupTypeId)) return adGroupType.name;
        }
        return "NO AD GROUP TYPES";
    };

    // SORT
    public sortColumnText() {
        this.columnTextOrder *= -1;
        let cacheCopy = this.$store.state.negativeActionCache.negativeActionList;
        let column = "text";
        // console.log(cacheCopy[0]);
        let compare = (a: any, b: any) => {
            if (a[column] < b[column]) {
                return (-1 * this.columnTextOrder);
            }
            if (a[column] > b[column]) {
                return (1 * this.columnTextOrder);
            }
            return 0;
        }
        cacheCopy.sort(compare);
    }

}
